import React from "react";
import Chart, {
  ChartMargin,
  DomainBounds,
  useChartScales,
} from "../chart/Chart";
import { Group } from "@visx/group";
import { format } from "d3-format";
import { DragHandleWithAnnotation } from "../chart/DragHandle";
import { PaddedPath, TargetCircle, ArrowIcon } from "../chart/Shapes";
import { clamp, pointOnCircle } from "../chart/util";
import { BottomAxis, LeftAxis } from "../chart/Axis";
import { FillArea } from "../chart/FillArea";
import { ForeignObject } from "../chart/ForeignObject";
import { monthFormat, monthInMs, yearFormat } from "../constants/Time";

const years = 4;
const months = years * 12;

const expensesColor = "#ffa0a0";
const revenueColor = "#64b9a1";
const growthColor = "#a29bfe";

interface GrowthChartProps {
  width: number;
  height: number;
  margin: ChartMargin;
  domainBounds: DomainBounds;
  cash: number;
  expenses: number;
  setExpenses: (expenses: number) => void;
  initialRevenue: number;
  setInitialRevenue: (revenue: number) => void;
  growthRate: number;
  setGrowthRate: (growthRate: number) => void;
}
export default function GrowthChart({
  width,
  height,
  margin,
  domainBounds,
  cash,
  expenses,
  setExpenses,
  initialRevenue,
  setInitialRevenue,
  growthRate,
  setGrowthRate,
}: GrowthChartProps) {
  const { xScale, yScale } = useChartScales(
    width,
    height,
    margin,
    domainBounds
  );
  // range bounds
  const radius = width / 3;
  const startDate = new Date(xScale.domain()[0]);

  const { xMin, xMax, yMin, yMax } = domainBounds;

  const endPoint = pointOnCircle(0, 0, radius, Math.PI / 4);
  const growthPath = `M ${radius} ${yScale(
    initialRevenue
  )} a ${radius} ${radius} 0 0 0 ${endPoint.x - radius} ${-endPoint.y}`;

  const breakEvenMonth =
    Math.log(expenses / initialRevenue) / Math.log(1 + growthRate);
  const breakEvenTime = xMin + breakEvenMonth * monthInMs;

  const showBreakEven = xMin <= breakEvenTime && breakEvenTime <= xMax;

  const capitalAreaPoints = getCapitalAreaPoints(
    xScale,
    yScale,
    domainBounds,
    expenses,
    initialRevenue,
    growthRate,
    breakEvenTime
  );

  const capitalNeeded = getCapitalNeeded(
    initialRevenue,
    expenses,
    growthRate,
    breakEvenMonth
  );
  const yMaxDomainMonths =
    Math.log(yMax / initialRevenue) / Math.log(1 + growthRate);

  const defaultAlive = cash >= capitalNeeded;

  const growthAngle = Math.atan(
    xScale(xMin + monthInMs * months) /
      (yScale(initialRevenue) -
        yScale(initialRevenue * (1 + growthRate) ** months))
  );

  const arcIntersection = pointOnCircle(
    margin.left,
    margin.top + yScale(initialRevenue),
    radius,
    // growthAngle - 90º (in radians)
    growthAngle - Math.PI / 2
  );

  const revenueLineEnd = getGrowthLineEnd(
    domainBounds,
    initialRevenue,
    growthRate
  );

  const revenuePath = `M${xScale(xMin)},${yScale(initialRevenue)}L${xScale(
    revenueLineEnd.x
  )},${yScale(revenueLineEnd.y)}`;

  const expensesPath = `M${xScale(xMin)},${yScale(expenses)}L${xScale(
    xMax
  )},${yScale(expenses)}`;

  return (
    <Chart height={height} width={width}>
      <Group left={margin.left} top={margin.top}>
        <FillArea points={capitalAreaPoints} />
        <LeftAxis
          scale={yScale}
          tickValues={[
            100, 300, 1_000, 3_000, 10_000, 30_000, 100_000, 300_000, 1_000_000,
            3_000_000, 100_000_000,
          ]}
        />
        <BottomAxis scale={xScale} top={yScale.range()[0]} />

        {/* Expenses (red) */}
        <PaddedPath
          d={expensesPath}
          stroke={expensesColor}
          strokeLinecap="round"
          fill="transparent"
        />
        {/* Revenue (green) */}
        <PaddedPath
          d={revenuePath}
          stroke={revenueColor}
          strokeLinecap="round"
          fill="transparent"
        />

        {/* Growth (purple) */}
        <PaddedPath
          d={growthPath}
          stroke={growthColor}
          fill="none"
          strokeLinecap="round"
        />

        <ForeignObject
          x={clamp(
            xScale(breakEvenTime),
            95,
            xScale.domain()[1].getTime() - 95
          )}
          y={yScale(expenses)}
          placement="top"
          offset={12}
        >
          <div className="bg-white p-2 rounded shadow">
            <span className="whitespace-nowrap text-xs font-medium text-gray-900">
              {`Profitable in ${monthFormat.format(breakEvenMonth)}`}
            </span>
          </div>
        </ForeignObject>

        <ForeignObject
          x={20}
          y={yScale(expenses)}
          placement="bottom-end"
          offset={20}
        >
          <div className="bg-white p-2 rounded">
            <span className="whitespace-nowrap text-xs font-medium text-gray-900">
              {`$${format(".3s")(capitalNeeded).toUpperCase()} Capital Needed`}
            </span>
          </div>
        </ForeignObject>

        {showBreakEven && (
          <TargetCircle
            x={xScale(breakEvenTime)}
            y={yScale(expenses)}
            fill="#3D7DFF"
          />
        )}

        <ForeignObject
          x={clamp(
            xScale(xMin + yMaxDomainMonths * monthInMs),
            xScale(xMin),
            xScale(xMax)
          )}
          y={yScale(yMax)}
          placement="left-end"
          offset={12}
        >
          <div className="bg-white p-2 rounded flex items-center space-x-2">
            <span className="whitespace-nowrap text-xs font-medium text-gray-900">
              {`$${format("0.1s")(yMax)} in revenue at ${yearFormat.format(
                yMaxDomainMonths / 12
              )}`}
            </span>
            {revenueLineEnd.x >= xMax && (
              <div className="w-4 h-4">
                <ArrowIcon />
              </div>
            )}
          </div>
        </ForeignObject>

        {cash > 0 && (
          <ForeignObject
            x={xScale.range()[1]}
            y={yScale.range()[0]}
            placement="top-start"
          >
            <div className="flex flex-col space-y-4 mr-6 mb-6">
              <Toaster icon={defaultAlive ? "😀" : "💀"}>
                {`Your company is default ${defaultAlive ? "alive!" : "dead"}`}
              </Toaster>
            </div>
          </ForeignObject>
        )}
      </Group>

      {/* Red expenses handle */}
      <DragHandleWithAnnotation
        color={expensesColor}
        annotationLabel={`$${format(".3s")(expenses)}`.toUpperCase()}
        width={width}
        height={height}
        x={margin.left}
        y={yScale(expenses) + margin.top}
        onDragMove={(e) => {
          const { y = 0, dy } = e;
          const dragValue = yScale.invert(y + dy - margin.top);
          const constrainedValue = clamp(dragValue, yMin, yMax);
          setExpenses(constrainedValue);
        }}
      />

      {/* Green revenue handle */}
      <DragHandleWithAnnotation
        color={revenueColor}
        annotationLabel={`$${format(".3s")(initialRevenue)}`.toUpperCase()}
        width={width}
        height={height}
        x={margin.left}
        y={yScale(initialRevenue) + margin.top}
        onDragMove={(e) => {
          const { y = 0, dy } = e;
          const dragValue = yScale.invert(y + dy - margin.top);
          const constrainedValue = clamp(dragValue, yMin, yMax);
          setInitialRevenue(constrainedValue);
        }}
      />

      {/* Purple growth handle */}
      <DragHandleWithAnnotation
        color={growthColor}
        annotationLabel={`${(growthRate * 100).toFixed(1)}%`}
        width={width}
        height={height}
        // TODO: use pointOnCircle
        x={arcIntersection.x}
        y={arcIntersection.y}
        onDragMove={(e) => {
          const { x = 0, y = 0, dx, dy } = e;

          const x0 = startDate.getTime();
          const y0 = initialRevenue;

          const x1 = xScale.invert(x + dx - margin.left);
          const y1 = yScale.invert(y + dy - margin.top);

          const months = Math.max(x1.getTime() - x0, 0) / monthInMs;
          const growthRate = (y1 / y0) ** (1 / months) - 1;
          const limitedGrowthRate = clamp(
            growthRate,
            Number.EPSILON,
            1_000_000
          );
          setGrowthRate(limitedGrowthRate);
        }}
      />
    </Chart>
  );
}

function getGrowthLineEnd(
  domainBounds: DomainBounds,
  originDomain: number,
  growth: number
) {
  const { xMin, xMax, yMax } = domainBounds;
  const monthsOnChart = (xMax - xMin) / monthInMs;
  const interceptMonth = Math.log(yMax / originDomain) / Math.log(1 + growth);
  const interceptTime = xMin + interceptMonth * monthInMs;
  // Line does not extend past the x-axis
  const doesExitEarly = interceptTime < xMax;
  const lineEndXMonths = doesExitEarly ? interceptMonth : monthsOnChart;
  const lineEndX = xMin + lineEndXMonths * monthInMs;
  const lineEndY = doesExitEarly
    ? yMax
    : originDomain * (1 + growth) ** lineEndXMonths;
  return { x: lineEndX, y: lineEndY };
}

function getCapitalAreaPoints(
  xScale: any,
  yScale: any,
  domainBounds: DomainBounds,
  expenses: number,
  initialRevenue: number,
  growthRate: number,
  breakEvenTime: number
) {
  const noArea = breakEvenTime < domainBounds.xMin;
  if (noArea) return "";

  const { xMin, xMax } = domainBounds;
  const isBreakEvenPointVisible = breakEvenTime <= xMax;
  const revenueAtXMax = initialRevenue * (1 + growthRate) ** months;

  const pathArray = isBreakEvenPointVisible
    ? [
        [xScale(xMin), yScale(expenses)],
        [xScale(breakEvenTime), yScale(expenses)],
        [xScale(xMin), yScale(initialRevenue)],
      ]
    : [
        [xScale(xMin), yScale(expenses)],
        [xScale(xMax), yScale(expenses)],
        [xScale(xMax), yScale(revenueAtXMax)],
        [xScale(xMin), yScale(initialRevenue)],
      ];

  return pathArray.join(" ");
}

function getCapitalNeeded(
  initialRevenue: number,
  initialExpenses: number,
  growthRate: number,
  breakEvenMonth: number
) {
  const integral = (months: number) =>
    (initialRevenue * (growthRate + 1) ** months) / Math.log(growthRate + 1);

  const totalExpenses = initialExpenses * breakEvenMonth;
  const capitalUnderRevenueCurve = integral(breakEvenMonth) - integral(0);
  return totalExpenses - capitalUnderRevenueCurve;
}
interface ToasterProps {
  icon: string;
  children: React.ReactNode;
}
function Toaster({ icon, children }: ToasterProps) {
  return (
    <div className="bg-white p-3 rounded flex items-center space-x-3 w-72 shadow-md">
      <div className="text-2xl w-8 h-8 flex-shrink-0">{icon}</div>
      <span className="text-sm font-medium text-gray-900">{children}</span>
    </div>
  );
}
