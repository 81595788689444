import React, { useState } from 'react';
import Tool from '../Tool';
import GrowthChart from './GrowthChart';

import GrowthSidebar from './GrowthSidebar';
import { Timeframe } from '../constants/Timeframe';
import { ChartMargin, DomainBounds } from '../chart/Chart';

const chartMargin: ChartMargin = {
  left: 70,
  top: 30,
  right: 10,
  bottom: 50,
};

const now = new Date();
const startDate = new Date(now.getFullYear(), now.getMonth(), 1);
const endDate = new Date(startDate.getFullYear() + 4, startDate.getMonth());

const domainBounds: DomainBounds = {
  yMin: 100,
  yMax: 3_000_000,
  xMin: startDate.getTime(),
  xMax: endDate.getTime(),
};

export default function GrowthCalculator() {
  const [timeframe, setTimeframe] = useState(Timeframe.Monthly);
  const [cash, setCash] = useState(0);
  const [expenses, setExpenses] = useState(100_000);
  const [initialRevenue, setInitialRevenue] = useState(5_000);
  const [growthRate, setGrowthRate] = useState(0.15);

  return (
    <Tool
      Sidebar={
        <GrowthSidebar
          timeframe={timeframe}
          setTimeframe={setTimeframe}
          cash={cash}
          setCash={setCash}
          expenses={expenses}
          setExpenses={setExpenses}
          initialRevenue={initialRevenue}
          setInitialRevenue={setInitialRevenue}
          growthRate={growthRate}
          setGrowthRate={setGrowthRate}
        />
      }
    >
      {({ width, height }) => (
        <GrowthChart
          width={width}
          height={height}
          margin={chartMargin}
          domainBounds={domainBounds}
          cash={cash}
          expenses={expenses}
          setExpenses={setExpenses}
          initialRevenue={initialRevenue}
          setInitialRevenue={setInitialRevenue}
          growthRate={growthRate}
          setGrowthRate={setGrowthRate}
        />
      )}
    </Tool>
  );
}
