import React from 'react';
import { Timeframe } from '../constants/Timeframe';
import {
  Sidebar,
  SidebarInput,
  SidebarSelect,
  SidebarTitle,
  SidebarAnchor,
  SidebarTextInfo,
} from '../infobars/Sidebar';

import DollarIcon from '@icons/dollar.svg';
import PercentIcon from '@icons/percent.svg';

interface GrowthSidebarProps {
  timeframe: Timeframe;
  setTimeframe: (timeframe: Timeframe) => void;
  cash: number;
  setCash: (cash: number) => void;
  expenses: number;
  setExpenses: (expenses: number) => void;
  initialRevenue: number;
  setInitialRevenue: (initialRevenue: number) => void;
  growthRate: number;
  setGrowthRate: (growthRate: number) => void;
}
export default function GrowthSidebar({
  timeframe,
  setTimeframe,
  cash,
  setCash,
  expenses,
  setExpenses,
  initialRevenue,
  setInitialRevenue,
  growthRate,
  setGrowthRate,
}: GrowthSidebarProps) {
  return (
    <Sidebar>
      <SidebarTitle>Revenue/Expenses</SidebarTitle>
      <SidebarSelect
        id="type"
        name="Revenue/Expenses Type"
        disabled
        value={timeframe}
        onChange={(e) => setTimeframe(e.target.value as Timeframe)}
      >
        {Object.entries(Timeframe).map(([key, value]) => (
          <option key={key} value={value}>
            {key}
          </option>
        ))}
      </SidebarSelect>
      <SidebarInput
        id="cash"
        name="Cash in Bank"
        value={cash}
        onValueChange={({ floatValue }) => {
          if (floatValue) {
            setCash(floatValue || 0);
          }
        }}
      >
        <DollarIcon className="w-4 h-4 fill-current text-gray-500" />
      </SidebarInput>
      <SidebarInput
        id="expenses"
        name="Expenses"
        color="#ffa0a0"
        value={expenses.toFixed(0)}
        onValueChange={({ floatValue }) => {
          if (floatValue) {
            setExpenses(floatValue);
          }
        }}
      >
        <DollarIcon className="w-4 h-4 fill-current text-gray-500" />
      </SidebarInput>
      <SidebarInput
        id="revenue"
        name="Revenue"
        color="#64b9a1"
        value={initialRevenue.toFixed(0)}
        onValueChange={({ floatValue }) => {
          if (!floatValue) return;
          if (floatValue > 300) {
            setInitialRevenue(floatValue);
          }
        }}
      >
        <DollarIcon className="w-4 h-4 fill-current text-gray-500" />
      </SidebarInput>
      <SidebarInput
        id="growth"
        name="Growth Rate"
        decimal={1}
        color="#a29bfe"
        value={growthRate * 100}
        onValueChange={({ floatValue }) => {
          if (floatValue) {
            setGrowthRate(floatValue / 100 || 0);
          }
        }}
      >
        <PercentIcon className="w-4 h-4 fill-current text-gray-500" />
      </SidebarInput>
      <SidebarTextInfo label="Read More">
        <SidebarAnchor href="#default-alive">
          Is my startup Default Alive or Default Dead?
        </SidebarAnchor>
        <SidebarAnchor href="#how-fast">
          How fast should my startup grow?
        </SidebarAnchor>
        <SidebarAnchor href="#profitability">
          How much money do we need to raise to reach profitability?
        </SidebarAnchor>
        <SidebarAnchor href="#max-spend">
          What is the maximum that I can spend per month?
        </SidebarAnchor>
      </SidebarTextInfo>
    </Sidebar>
  );
}
